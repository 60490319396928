import { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Loading } from '@/components/loading';
import { Center } from '@/components/center';
import { PendingInvitationsBox } from '@/components/pending-invitations-box';
import { AppLayout } from '@/components/app-layout';
import { useMeInvitations } from '@/hooks/use-me-invitations';
import { useSessionQuery } from '@/api/login';
import {
  useAcceptMembershipInvitationMutation,
  useDeclineMembershipInvitationMutation,
} from '@/api/user';
import { useToast } from '@kinesis/bungle';
import { launch } from '@/services/launch';
import { InvitationError } from '@/components/invitation-error';

const Invitations = () => {
  const toast = useToast('globalTop');
  const { data: session, isSuccess, isLoading } = useSessionQuery();

  const { data: invitations, isLoading: invitationsLoading } =
    useMeInvitations();

  const [invitationAccept, { isLoading: isAccepting }] =
    useAcceptMembershipInvitationMutation();
  const [invitationDecline, { isLoading: isDeclining }] =
    useDeclineMembershipInvitationMutation();
  const [showInvitationError, setShowInvitationError] = useState(false);

  const onAcceptInvitation = useCallback(
    async (token: string) => {
      const invitation = invitations.find((i) => i.token === token);
      if (invitation && !invitation.canBeAccepted) {
        return setShowInvitationError(true);
      }

      try {
        await invitationAccept(token).unwrap();
      } catch (e) {
        toast('Error accepting invitation.', {
          variant: 'error',
          duration: 6000,
        });
      }
    },
    [invitationAccept, invitations, toast],
  );

  const onDeclineInvitation = useCallback(
    async (token: string) => {
      try {
        await invitationDecline(token).unwrap();
      } catch (e) {
        toast('Error declining invitation.', {
          variant: 'error',
          duration: 6000,
        });
      }
    },
    [invitationDecline, toast],
  );

  const handleDone = useCallback(() => {
    if (session?.orphan) {
      return <Navigate to='/registration/complete' />;
    } else {
      launch(session?.target, true);
    }
  }, [session]);

  if (!isLoading && isSuccess) {
    if (!invitationsLoading) {
      return (
        <AppLayout>
          <>
            {showInvitationError && (
              <InvitationError onClose={() => setShowInvitationError(false)} />
            )}
            <Center>
              <PendingInvitationsBox
                onAccept={onAcceptInvitation}
                onDecline={onDeclineInvitation}
                onDone={handleDone}
                invitations={invitations}
                isLoading={isAccepting || isDeclining}
              />
            </Center>
          </>
        </AppLayout>
      );
    } else {
      return <Loading />;
    }
  }

  return null;
};

export { Invitations };
