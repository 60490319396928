import { useCallback, useEffect, useState, useMemo } from 'react';
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { Center } from '@/components/center';
import { useToast } from '@kinesis/bungle';
import { RegistrationEmail } from '@/components/registration-request';
import {
  RegistrationProfile,
  RegistrationProfileDetails,
} from '@/components/registration-profile';
import { RegistrationSso } from '@/components/registration-sso';
import { Loading } from '@/components/loading';
import { useGetInvitationQuery } from '@/api/organisation';
import { useProfileAcceptInvitationMutation } from '@/api/login';
import { setLastLogin } from '@/services/last-login';
import { login } from '@/services/login';
import { Invitation } from '@/types';

type InvitationState =
  | { state: 'data' }
  | { state: 'override'; invitation: Invitation };

const PendingInvitation = () => {
  const toast = useToast('globalTop');
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const token = search.get('token') ?? '';

  const { data: invitationData, isLoading: isInvitationLoading } =
    useGetInvitationQuery(token);

  const [invitationState, setInvitationState] = useState<InvitationState>({
    state: 'data',
  });
  const [profileAccept, { isLoading: isProfileAcceptLoading }] =
    useProfileAcceptInvitationMutation();

  const invitation = useMemo(
    () =>
      invitationState.state === 'override'
        ? invitationState.invitation
        : (invitationData as Invitation),
    [invitationState, invitationData],
  );

  useEffect(() => {
    if (!isInvitationLoading) {
      if (invitation?.state === 'expired') {
        toast('Invitation has expired, please ask for it to be re-issued.', {
          variant: 'error',
          duration: 6000,
        });
      }
      if (invitation?.state === 'registered') {
        toast('Please log in to accept invitation.', { variant: 'success' });
      }
      if (invitation?.state === 'not-found') {
        toast('Invitation not found.', {
          variant: 'error',
          duration: 6000,
        });
      }
    }
  }, [invitation, isInvitationLoading, toast]);

  const onUseCredentials = useCallback(
    async (request: RegistrationEmail) => {
      const { email } = request;
      if (invitation && invitation.state === 'sso') {
        setInvitationState({
          state: 'override',
          invitation: {
            state: 'profile',
            email,
            code: invitation.code,
          },
        });
      }
    },
    [invitation],
  );

  const onLoginSso = useCallback(() => {
    if (invitation && invitation.state === 'sso') {
      setLastLogin(invitation.email);
      window.location.assign(invitation.redirectUrl);
    }
  }, [invitation]);

  const onRegisterProfile = useCallback(
    async (request: RegistrationProfileDetails) => {
      try {
        const data = await profileAccept({
          invitationToken: request.token,
          details: request,
        }).unwrap();
        setLastLogin(request.email);
        login(data, false, navigate);
      } catch (e) {
        toast('We couldn’t register your account. Please try again.', {
          variant: 'error',
          duration: 6000,
        });
      }
    },
    [profileAccept, toast, navigate],
  );

  if (!isInvitationLoading) {
    return (
      <Center>
        {invitation?.state === 'sso' && (
          <RegistrationSso
            organisation={invitation.organisation}
            organisationMandatesSso={invitation.organisationMandatesSso}
            email={invitation.email}
            onLogin={onLoginSso}
            onUseCredentials={onUseCredentials}
          />
        )}
        {invitation?.state === 'profile' && (
          <RegistrationProfile
            onRegistration={onRegisterProfile}
            isRegistering={isProfileAcceptLoading}
            code={invitation.code}
            token={token}
            email={invitation.email}
          />
        )}
        {invitation?.state === 'registered' && <Navigate replace to='/' />}
        {invitation?.state === 'expired' && <Navigate replace to='/' />}
        {invitation?.state === 'not-found' && <Navigate replace to='/' />}
      </Center>
    );
  } else {
    return <Loading />;
  }
};

export { PendingInvitation };
