import { useTheme } from 'styled-components';
import { Icon, CenteredPanel } from '@kinesis/bungle';

const Loading = () => {
  const theme = useTheme() as any;
  return (
    <CenteredPanel centerY>
      <Icon
        animation='rotate'
        color={theme.color.blue6}
        magnitude='large'
        type='spinner'
      />
    </CenteredPanel>
  );
};
export { Loading };
