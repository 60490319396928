import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from 'react-router-dom';
import { App } from './pages/app';
import { Authenticated } from './pages/authenticated';
import { StyledAuthenticated } from './pages/styled-authenticated';
import { StyledApp } from './pages/styled-app';
import { SsoCallback } from './pages/sso-callback';
import { Login } from './pages/login';
import { Terms } from './pages/terms';
import { VanityLogin } from './pages/vanity-login';
import { Registration } from './pages/registration';
import { PasswordReset } from './pages/password-reset';
import { PendingInvitation } from './pages/pending-invitation';
import { Invitations } from './pages/invitations';
import { Settings } from './pages/settings';
import { SettingsIndex } from './pages/settings-index';
import { SettingsUserProfile } from './pages/settings-user-profile';
import { SettingsUserDefaults } from './pages/settings-user-defaults';
import { SettingsAcknowledgements } from './pages/settings-acknowledgements';
import { SettingsAnalytics } from './pages/settings-analytics';
import { SettingsAccessKeys } from './pages/settings-access-keys';
import { SettingsOrganisationNew } from './pages/settings-organisation-new';
import { SettingsOrganisationProfile } from './pages/settings-organisation-profile';
import { SettingsOrganisationMembers } from './pages/settings-organisation-members';
import { SettingsOrganisationServiceAccounts } from './pages/settings-organisation-service-accounts';
import { SettingsOrganisationDefaults } from './pages/settings-organisation-defaults';
import { SettingsOrganisationLicences } from './pages/settings-organisation-licences';
import { SettingsOrganisationSso } from './pages/settings-organisation-sso';
import { StandaloneSwitcher } from './pages/standalone-switcher';
import { FirstOrganisation } from './pages/first-organisation';
import { PendingTerms } from './pages/pending-terms';
import { Redirect } from './pages/redirect';
import { ErrorNotFound } from './pages/error-not-found';
import { ErrorServer } from './pages/error-server';
import { ErrorUnknown } from './pages/error-unknown';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorNotFound />}>
      <Route path='/switcher' element={<StandaloneSwitcher />} />
      <Route path='/' element={<App />}>
        <Route path='/errors/not-found' element={<ErrorNotFound />} />
        <Route path='/errors/server' element={<ErrorServer />} />
        <Route path='/errors/unknown' element={<ErrorUnknown />} />
        <Route element={<StyledApp />}>
          <Route index element={<Login />} handle={{ publicOnly: true }} />
          <Route
            path='login'
            element={<Redirect />}
            handle={{ publicOnly: true }}
          />
          <Route
            path='login/:organisationCode'
            element={<VanityLogin />}
            handle={{ publicOnly: true }}
          />
          <Route
            path='registration'
            element={<Registration />}
            handle={{ publicOnly: true }}
          />
          <Route path='registration/complete' element={<FirstOrganisation />} />
          <Route path='registration/terms' element={<Terms />} />
          <Route
            path='password-reset'
            element={<PasswordReset />}
            handle={{ publicOnly: true }}
          />
          <Route
            path='invitations/pending'
            element={<PendingInvitation />}
            handle={{ publicOnly: true }}
          />
        </Route>
        <Route
          path='session/callback'
          element={<SsoCallback />}
          handle={{ publicOnly: true }}
        />
        <Route element={<Authenticated />}>
          <Route path='invitations' element={<Invitations />} />
          <Route path='terms/:namespace/:entityId' element={<PendingTerms />} />
          <Route element={<StyledAuthenticated />}>
            <Route path='settings' element={<Settings />}>
              <Route index element={<SettingsIndex />} />
              <Route
                path='account/profile'
                element={<SettingsUserProfile />}
                handle={{
                  settingType: 'user',
                  settingKey: 'profile',
                }}
              />
              <Route
                path='account/defaults'
                element={<SettingsUserDefaults />}
                handle={{
                  settingType: 'user',
                  settingKey: 'defaults',
                }}
              />
              <Route
                path='account/analytics'
                element={<SettingsAnalytics />}
                handle={{
                  settingType: 'user',
                  settingKey: 'analytics',
                }}
              />
              <Route
                path='account/access-keys'
                element={<SettingsAccessKeys />}
                handle={{
                  settingType: 'user',
                  settingKey: 'keys',
                }}
              />
              <Route
                path='organisation/new'
                element={<SettingsOrganisationNew />}
                handle={{
                  settingType: 'new-organisation',
                  settingKey: 'new-organisation',
                }}
              />
              <Route
                path='organisation/:organisationId/profile'
                element={<SettingsOrganisationProfile />}
                handle={{
                  settingType: 'organisation',
                  settingKey: 'profile',
                }}
              />
              <Route
                path='organisation/:organisationId/members'
                element={<SettingsOrganisationMembers />}
                handle={{
                  settingType: 'organisation',
                  settingKey: 'members',
                }}
              />
              <Route
                path='organisation/:organisationId/service-accounts'
                element={<SettingsOrganisationServiceAccounts />}
                handle={{
                  settingType: 'organisation',
                  settingKey: 'service-accounts',
                }}
              />
              <Route
                path='organisation/:organisationId/defaults'
                element={<SettingsOrganisationDefaults />}
                handle={{
                  settingType: 'organisation',
                  settingKey: 'defaults',
                }}
              />

              <Route
                path='organisation/:organisationId/licences'
                element={<SettingsOrganisationLicences />}
                handle={{
                  settingType: 'organisation',
                  settingKey: 'licences',
                }}
              />
              <Route
                path='organisation/:organisationId/sso'
                element={<SettingsOrganisationSso />}
                handle={{
                  settingType: 'organisation',
                  settingKey: 'sso',
                }}
              />
              <Route
                path='acknowledgements'
                element={<SettingsAcknowledgements />}
                handle={{
                  settingType: 'acknowledgements',
                  settingKey: 'acknowledgements',
                }}
              />
            </Route>
            {import.meta.env.MODE === 'development' && (
              <Route
                path='inbox'
                element={<div>inbox, production would be suit</div>}
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='inventories'
                element={
                  <div>inventory placeholder, production would be suit</div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='inventories/:inventoryId/'
                element={
                  <div>inventory placeholder, production would be suit</div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='inboxes'
                element={<div>inbox, production would be suit</div>}
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='workspaces'
                element={
                  <div>
                    workspaces placeholder, production would be marathon
                  </div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='pathways'
                element={
                  <div>pathways placeholder, production would be marathon</div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='datasets'
                element={
                  <div>datasets placeholder, production would be marathon</div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='datasets/:datasetId/'
                element={
                  <div>datasets placeholder, production would be marathon</div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='city'
                element={<div>city placeholder, production would be city</div>}
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='city/:instanceId/'
                element={<div>city placeholder, production would be city</div>}
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='integrated'
                element={
                  <div>
                    integrated placeholder, production would be integrated
                  </div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='integrated/:instanceId/'
                element={
                  <div>
                    integrated placeholder, production would be integrated
                  </div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='bill-management'
                element={
                  <div>
                    bill-management placeholder, production would be wrench
                  </div>
                }
              />
            )}
            {import.meta.env.MODE === 'development' && (
              <Route
                path='bill-management/:instanceId/'
                element={
                  <div>
                    bill-management placeholder, production would be wrench
                  </div>
                }
              />
            )}
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);

export { router };
