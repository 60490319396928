export type LoginMethod = {
  email?: string;
  method?: 'sso' | 'db';
  redirectUrl?: string;
};

export type Namespace =
  | 'city'
  | 'integrated'
  | 'workspace'
  | 'inventory'
  | 'dataset'
  | 'pathways'
  | 'bill-management';

export type Launch = {
  namespace: Namespace;
  id?: string;
};

export type Entity = {
  namespace: Namespace;
  id: string;
};

export type AuthenticatedSource = 'login' | 'session';

export type AcceptedLogin = {
  accessToken: string;
  orphan: boolean;
  acceptedTerms: boolean;
  analytics?: AnalyticsLevel;
  pendingInvitations: boolean;
  name: string;
  firstName: string;
  lastName: string;
  initials: string;
  email: string;
  target?: Launch;
  source: AuthenticatedSource;
};

export type Session = AcceptedLogin;

export type AccessKey = {
  id: string;
  created: string;
};

export type AccessKeySecret = {
  id: string;
  created: string;
  private: string;
};

export type Organisation = {
  id: number;
  name: string;
  avatar?: string;
  industry?: string;
  ssoEnabled?: boolean;
  mandatesSso?: boolean;
  ssoDomains?: string[];
  dataStorage: 'au' | 'us';
};

export type OrganisationVanityLogin = {
  id: number;
  name: string;
  avatar?: string;
};

export type UpdateOrganisation = {
  id: number;
  name: string;
  avatar?: string;
  industry?: string;
};

export type NewOrganisation = {
  name: string;
  dataStorage: 'au' | 'us';
  avatar?: string;
  industry?: string;
};

export type AnalyticsLevel = 'full' | 'limited';

export type User = {
  id: number;
  email: string;
  analytics: AnalyticsLevel;
  firstName: string;
  lastName: string;
  jobTitle?: string;
};

export type Me = {
  id: number;
  email: string;
  analytics: AnalyticsLevel;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  keys: AccessKey[];
  memberships: Membership[];
};

export type MeUpdate = {
  id: number;
  analytics: AnalyticsLevel;
  firstName: string;
  lastName: string;
  jobTitle?: string;
};

export type InvitationData = {
  token: string;
  organisationName: string;
  avatar?: string;
  invitedBy: string;
  expiresAt: string;
  canBeAccepted?: boolean;
};

export type Invitation =
  | { state: 'registered' }
  | {
      state: 'sso';
      organisationMandatesSso: boolean;
      email: string;
      organisation: string;
      code: string;
      redirectUrl: string;
    }
  | { state: 'profile'; email: string; code: string }
  | { state: 'expired' }
  | { state: 'not-found' };

export type ServiceAccount = {
  id: number;
  name: string;
  keys: AccessKey[];
};

export type NewServiceAccount = {
  name: string;
};

export type Member = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  initials: string;
  jobTitle?: string;
  lastLogin?: string;
  membership: Membership;
};

export type Membership = {
  id: number;
  organisationId: number;
  organisationName: string;
  organisationAvatar?: string;
  inviterName?: string;
  status: 'active' | 'pending';
  invitationExpired?: boolean;
  invitationExpiresAt?: string;
  invitationCanBeAccepted?: boolean;
  invitationToken?: string;
  permissions: {
    editor: boolean;
  };
};

export const Products = [
  'nzo',
  'workspaces',
  'pathways',
  'city',
  'integrated',
  'bill-management',
] as const;
export type Product = (typeof Products)[number];

export type DefaultProductOption =
  | { type: 'last-visited' }
  | { type: 'default-for-organisation' }
  | { type: 'product'; product: Product };

export type ProductLaunchPreference =
  | { type: 'last-visited' }
  | { type: 'default-for-organisation' }
  | { type: 'entity'; id: string };

export type LaunchSelection = {
  namespace: Namespace;
  preference: ProductLaunchPreference;
};

export type ProductLaunchOption = {
  name?: string;
  preference: ProductLaunchPreference;
};

export type LaunchOptions = {
  namespace: Namespace;
  options: ProductLaunchOption[];
};

export type DefaultGridProductOption = {
  label: string;
  value: ProductLaunchPreference;
};

export type DefaultGridProduct = {
  product: Product;
  selection?: ProductLaunchPreference;
  options: DefaultGridProductOption[];
};

export type Licence = {
  key: Product;
  licensed: boolean;
};

export type Flag = {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
  editable: boolean;
};

export type TermsItem = {
  accepted?: boolean;
  id: number;
  name: string;
  ownerAvatarUrl: string;
  ownerName: string;
  text: string;
  declined: boolean;
};
